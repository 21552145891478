<template>
  <div>
    <form-wrapper
      :action="form.action"
      :valid="valid"
      :fields="form.fields"
      :firstLoad="firstLoad"
      @passValid="$emit('passValid', $event)"
    >
      <!--Header-->
      <template #formTitle>
        <!--Error Message-->
        <FormTitle
          :form="{ header: form.header }"
          :firstLoad="firstLoad"
          ref="title"
        />
      </template>
      <!--Form Fields-->
      <template #formFields>
        <FormFields
          :fields="form.fields"
          @passChange="passChange($event)"
          :firstLoad="firstLoad"
        />
      </template>
      <!--Actions-->
      <template #formActions>
        <FormActions
          :form="form"
          :loading="loading"
          :valid="valid"
          :firstLoad="firstLoad"
          :isNew="isNew"
          @openDeleteDialog="deleteDialog = $event"
          @passRefresh="$emit('passRefresh', $event)"
        />
      </template>
    </form-wrapper>
    <FormDeleteConfirmModal
      :deleteDialog="deleteDialog"
      :form="form"
      @closeDialog="deleteDialog = false"
    />
  </div>
</template>

<script>
import mixin_UserRoles from "../../globalActions/mixin_UserRoles";
export default {
  name: "Form",
  props: {
    form: {
      type: Object,
      required: true,
    },
    valid: Boolean,
    loading: Boolean,
    isNew: Boolean,
  },
  mixins: [mixin_UserRoles],
  components: {
    FormWrapper: () => import("../Forms/FormWrapper.vue"),
    FormTitle: () => import("../Forms/FormTitle.vue"),
    FormFields: () => import("../Forms/FormFields.vue"),
    FormActions: () => import("../Forms/FormActions.vue"),
    FormDeleteConfirmModal: () => import("../Forms/FormDeleteConfirmModal.vue"),
  },
  data() {
    return {
      deleteDialog: false,
    };
  },
  methods: {
    passChange(input) {
      this.$emit("passChange", input);
    },
  },
};
</script>